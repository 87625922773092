
export const dateConvertToStringToDate = (date: string | null | undefined): string => {
  if (date) {
    return date.replace('T', ' ').split(':', 2).join(':')
  }
  return '-'
}

export const formatUSDateTime = (date: string): string => {
  const dateTime = new Date(date)
  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0')
  const day = dateTime.getDate().toString().padStart(2, '0')
  const year = dateTime.getFullYear().toString().slice(-2)
  let hours = dateTime.getHours()
  const ampm = hours < 12 ? 'AM' : 'PM'
  hours = hours % 12 || 12
  const minutes = dateTime.getMinutes().toString().padStart(2, '0')
  const formattedUSDateTime = `${month}/${day}/${year} ${hours}:${minutes}${ampm}`
  return formattedUSDateTime
}


export const dateConvertUsFormat = (date: Date): string => {

  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
}


export const convertDateTime = (dateString: string, byHours = false ): string =>{
  const date = new Date(dateString)

  const pad = (num: number): string => num.toString().padStart(2, '0')

  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const year = date.getFullYear().toString().slice(-2)

  let hours = date.getHours()
  const minutes = pad(date.getMinutes())
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12 || 12
  const formattedTime = `${hours}:${minutes}${ampm}`
  return `${month}/${day}/${year} ${byHours ? formattedTime : ''}`

}