import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { saveCustomOrder } from 'src/api/addOrder/addOrder'
import { ILikelyProducts } from 'src/api/addOrder/model'
import { IItemsInOrder } from 'src/api/Preflight/orderList/OrderListSummary/model'
import IPC from 'src/Components/IPC/IPC'
import { ipcStore } from 'src/stores/IPC/ipcStore'
import { MetaData } from '../../../../ui/MetaData'
import classes from './CreateOrder.module.scss'
import CreateOrderInfoBlock from './CreateOrderInfoBlock/CreateOrderInfoBlock'
import CustomerBlock from './CustomerBlock/CustomerBlock'
import { ICustomOrder } from './model'
import ProjectInfoBlock from './ProjectInfoBlock/ProjectInfoBlock'

const initialOrderData = {
  job: [],
  shipping_method: '',
  billing_method: 0,
  asap: false,
  billing_address: 0,
  shipping_address: 0,
  customer_id: 0,
  shipping_cost: 0,
  printing_cost: 0,
  taxes_cost: 0,
  total_cost: 0
}
const CreateOrder = (): JSX.Element => {
  const [order, setOrder] = useState<ILikelyProducts>()
  const [customOrder, setCustomOrder] = useState<ICustomOrder>(initialOrderData)
  const [customerId, setCustomerId] = useState<number>()
  const { loadingStatus: isLoading } = ipcStore
  const returnIPC = useCallback(() => {
    return <>
      <IPC inCreateOrder productGroupName={order?.productGroupName} setCustomOrder={setCustomOrder} order={order as unknown as IItemsInOrder} attributes={{}} />
    </>
  }, [order?.productGroupName, isLoading])
  function changeJob<T> (index: number, name: string, argument: T): void {
    setCustomOrder(prev => {
      return { ...prev, job: [...prev.job.map((job, i) => i === index ? { ...job, [name]: argument } : job)] }
    })
  }
  const cleanPage = (): void => {
    setCustomOrder(initialOrderData)
  }
  const isShow = order && customerId && customOrder.job?.length
  useEffect(() => {
    if (customerId) {
      setCustomOrder((prev) => ({ ...prev, customer_id: customerId }))
    }
  }, [customerId])
  const handleSaveOrder = async (): Promise<void> => {
    try {
      const res = await saveCustomOrder(customOrder)
      console.log('res :>> 55', res)
      toast.success(`Order ${res.orderId} created successfully with ${res.jobs.length === 1 ? 'job' : 'jobs'} ${res.jobs}`)
      setCustomOrder(initialOrderData)
    } catch (error) {
      console.log('error :>> 60', error)
      toast.error('Something went wrong')
    }
  }
  return (
    <div className={classes.createOrder} >
      <MetaData title='Add Order' />
      <div className={classes.createOrder_container}>
        <div className={classes.createOrder_container_leftBlock}>
          <CustomerBlock setOrder={setOrder} setSelectedCustomer={setCustomerId} />
          {order && customerId ? <div className={classes.createOrder_container_leftBlock_ipcContainer}>
            <div className={classes.createOrder_container_leftBlock_ipcContainer_ipc}>
              <h1>IPC</h1>
            </div>
            {returnIPC()}
          </div> : null}
          {isShow ? <div>
            <CreateOrderInfoBlock customerId={customerId} customOrder={customOrder} setCustomOrder={setCustomOrder} />
          </div> : null}
        </div>
        {isShow ? <>
          {customOrder.job.map((job, index) => <div key={index} className={classes.createOrder_container_rightBlock}>
            <ProjectInfoBlock files={job.files} jobIndex={index} changeJob={changeJob} />
          </div>)}
          <div className={classes.createOrder_buttons}>
            <button onClick={handleSaveOrder}>Save Order</button>
            <button onClick={() => cleanPage()}>Clean Page</button>
          </div>
        </> : <></>}
      </div>
    </div>
  )
}

export default observer(CreateOrder)