import { api } from 'src/api/api'
import { ICostUpdate, IPatchOrderShipping, IPostCalculateShippingResponse, ISkuLabelInfo } from './model'

export const putCoastUpdate = (orderId: string, data: ICostUpdate): Promise<void> => 
  api.put(`/dashboard/orders/${orderId}/updateShippmentCost`,data).then(res => res.data)

export const patchCalculateMultiShipping = (data: IPatchOrderShipping): Promise<IPostCalculateShippingResponse> => 
  api.patch('/dashboard/orders/calculateMultiShipping', data).then(res => res.data)

export const getSkuLabelInfo = (jobId: number): Promise<ISkuLabelInfo[]> =>
  api.get(`/dashboard/detail/skuLabelInfo/${jobId}`).then(res => res.data)