import PageHeader from 'src/Components/Layout/PageHeader/PageHeader'
import classes from './CustomerTabs.module.scss'
import CreateCustomer from './CreateCustomer/CreateCustomer'
import { tabData } from 'src/ui/Tab/tabData'
import Tab from 'src/ui/Tab/Tab'
import { useParams } from 'react-router-dom'
import { Suspense, lazy, useEffect, useState } from 'react'
import { IGetCustomerById } from 'src/api/customers/model'
import { getOneCustomers } from 'src/api/customers/customers'
import useActiveTab from 'src/ui/Tab/activeTab'
const Notes = lazy(() => import('./Notes/Notes'))
const Coupons = lazy(() => import('./Coupons/Coupons')) 
const Designs = lazy(() => import('./Designs/Designs'))
const Contacts = lazy(() => import('./Contacts/Contacts')) 
const History = lazy(() => import('./History/History'))
const AddressBook = lazy(() => import('./AddressBook/AddressBook')) 
const CustomerTabs = (): JSX.Element => {
  const { tabName, changeTab } = useActiveTab('Notes')
  const { id } = useParams()
  const [customerInfo, setCustomerInfo ] = useState<IGetCustomerById>({} as IGetCustomerById)

  const getCustomerById = async (id: string): Promise<void> => {
    try {
      setCustomerInfo( await getOneCustomers(id))
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if (id) {
      getCustomerById(id)
    }
  },[id])
  


  return (
    <div className={classes.customerTabs}>
      <div className={classes.customerTabs_header}>
        <PageHeader pageName='Edit Customer'/>
      </div>
      <div className={classes.customerTabs_container}>
        <CreateCustomer
          userId={id}
          customerInfo={customerInfo}
          authToken={customerInfo.authToken}
          setCustomerInfo={setCustomerInfo}
        />
        <div className={classes.customerTabs_container_rightColumn}>
          <div className={classes.customerTabs_container_rightColumn_tabs}>
            {
              tabData.customerTabs.map(item => (
                <Tab key={item.id} tab={item.title} changeTab={changeTab} tabName={tabName}/>
              ))
            }
          </div>
          <div className={classes.customerTabs_container_rightColumn_info}>
            <Suspense fallback={null}>
              {
                tabName === 'Address Book' &&
              <AddressBook customerId={String(customerInfo.salesCustomers?.customerId)}/>
              }
              {
                tabName === 'History' &&
              <History customerId={customerInfo.salesCustomers?.customerId}/>
              }
              {
                tabName === 'Coupons' &&
              <Coupons customerId={customerInfo.salesCustomers?.customerId}/>
              }
              {
                tabName === 'Designs' &&
              <Designs/>
              }
              {
                tabName === 'Contacts' &&
              <Contacts customerId={customerInfo.salesCustomers?.customerId}/>
              }
              {
                tabName === 'Notes' &&
              <Notes customerId={customerInfo.salesCustomers?.customerId}/>
              }
            </Suspense>
          </div>
        </div>
      </div>
    </div>)
}

export default CustomerTabs