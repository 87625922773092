import { api } from 'src/api/api'
import { IParams } from './OrderListNote/model'
import { IExportQrData, IGetForAllTabs, IGetOrderListData, IOrderSettings } from './model'

export const getOrderList = (params: IParams): Promise<IGetOrderListData> => 
  api.get('/dashboard/orders', { params }).then(res => res.data).catch((error) => error.data)

export const getOrderById = <T,>(id: string, field: string): Promise<T> =>
  api.get(`/dashboard/orders/${id}/${field}`).then(res => res.data)

export const getOrderSettings = (): Promise<IOrderSettings> =>
  api.get('/dashboard/orders_settings').then(res => res.data)

export const getForAllTabs = (jobId: string): Promise<IGetForAllTabs> =>
  api.get(`/dashboard/orders/${jobId}/forAllTabs`).then(res => res.data)

export const getExportQr = (jobId: string): Promise<IExportQrData> =>
  api.get(`/dashboard/jobs/${jobId}/exportqr`).then(res => res.data)

export const postBeginTrnQbExport = (orderId: number): Promise<void> => 
  api.post('/dashboard/orders/beginTrnQbExport',{ orderId }).then(res => res.data)