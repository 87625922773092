import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IParams, IRep } from 'src/api/Preflight/orderList/OrderListNote/model'
import { IGetOrderListData, IOrder, IOrderSettings, IStage } from 'src/api/Preflight/orderList/model'
import { getOrderList, getOrderSettings } from 'src/api/Preflight/orderList/orderList'
import { getRepps, putLoginAsUser } from 'src/api/auth/auth'
import { IUserData } from 'src/api/auth/model'
import { userStore } from 'src/stores/userStore'
import Filter from 'src/ui/Filter/Filter'
import { ISelectData } from 'src/ui/Filter/model'
import { MetaData } from 'src/ui/MetaData'
import { IResponseError } from 'src/utils/errorMessage/model'
import { setCookies } from 'src/utils/storageFunction'
import Loading from '../../Loading/Loading'
import classes from './OrderList.module.scss'
import OrderListItem from './OrderListItem/OrderListItem'
const OrderList: FC<{requestPath: string}> = ({ requestPath }): JSX.Element => {
  const user: IUserData | undefined = userStore.user
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [repsInfo, setRepsInfo] = useState<IRep[]>([])
  const [settingsInfo, setSettingsInfo] = useState<IOrderSettings>({
    stagesWithStates:[],
    qbBackup:[],
    isAdministrator: false
  })
  const [filteredOrders, setFilteredOrders] = useState<IGetOrderListData>({} as IGetOrderListData)
  const [stageLabelId, setStageLabelId] = useState<number>(0)
  const [params, setParams] = useState<IParams>({ method: requestPath })
  useEffect(() => {
    if (params.method !== requestPath) {
      setParams({ method: requestPath })
    }
  }, [requestPath])

  const order = (): Promise<void> =>{
    setIsLoading(true)
    return getOrderList(params)
      .then((res) => {
        setFilteredOrders(res)
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }


  const updateSettingsWithStages = (data: IStage[], stageLabelId: number): ISelectData => {
    const updatedSettings: ISelectData = {
      stage: [],
      states: [],
    }
    return data.reduce((updatedSettings, stage) => {
      const updatedStages = [...updatedSettings.stage, {
        label: stage.name,
        value: String(stage.labelId),
      }]


      if (stage.states?.length) {
        if (stage.labelId === stageLabelId || stageLabelId === 0) {
          const updatedStates = stage.states.map(state => ({
            label: state.name,
            value: String(state.labelId),
          }))

          const mergedStates = Array.isArray(updatedSettings.states)
            ? [...updatedSettings.states, ...updatedStates]
            : updatedStates

          return {
            ...updatedSettings,
            stage: updatedStages,
            states: mergedStates,
          }
        }
      }

      return {
        ...updatedSettings,
        stage: updatedStages,
      }
    }, updatedSettings)
  }

  const orderSettings = async (): Promise<void> => {
    try {
      const res = await getOrderSettings()
      setSettingsInfo(res)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (!settingsInfo.stagesWithStates.length) {
      orderSettings()
    }
  },[settingsInfo])

  useEffect(() => {
    setStageLabelId(Number(params?.stage ?? 0))
    if ('stage' in params && !settingsInfo.stagesWithStates.find(e => e.labelId === Number(params.stage))?.
      states?.some( state => state.labelId === Number(params.state))) {
      delete params.state
    }
    order()
  },[params])

  const orderFilter = (data: IParams): void => {
    delete data.method
    if ('searchFor' in data && !data.searchFor.length) delete data.searchFor
    if (!Object.keys(data).length) {
      setParams({ method: requestPath })
    } else {
      if (requestPath === 'hot_jobs' && user?.userId) {
        setParams({
          method: 'search',
          hotJob: '1',
          trackers: String(user?.userId),
          ...data
        })
      } else {
        setParams({
          method: 'search',
          ...data

        })
      }
    }
  }
  const resetFilter = (): void => {
    setParams({ method: requestPath })
  }

  const metaDataTitle = (method: string): string => {
    if (method === 'new_dashboard') {
      return 'Dashboard'
    } else if (method === 'my_orders') {
      return 'My orders'
    } else if (method === 'hot_jobs') {
      return 'HOT JOBS'
    }
    return 'Canceled online'
  }

  const getRepsData = async (): Promise<void> => {
    try {
      setRepsInfo(await getRepps())
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }
    }
  }
  useEffect(() => {
    getRepsData()
  },[])

  const loginAsUser = async (username: string): Promise<void> => {
    try {
      const res = await putLoginAsUser(username)
      setCookies('access-token', res.accessToken)
      window.location.reload()
    } catch (error) {
      const errorMessage = error as IResponseError
      if ('detail' in errorMessage.response.data) {
        toast.error(errorMessage.response.data.detail)
      }

    }
  }
  const showInfoTdBlock = (orders: IOrder[]): boolean => {
    return orders.some(e => (e?.color && e.color !== null) || e?.hcp !== '0' || e?.outsourced || e?.directmail || e?.online_design)
  }
  return (
    <>
      <MetaData title={metaDataTitle(requestPath)}/>
      {
        isLoading ? <Loading/> :
          <div className={classes.orderList}>
            <div className={classes.orderList_container}>

              <div className={classes.orderList_container_header}>
                <Filter
                  params={params}
                  pageName='myOrder'
                  selectName={['','stage', 'states']}
                  selectData={updateSettingsWithStages(settingsInfo.stagesWithStates, stageLabelId)}
                  handleFilter={orderFilter}
                  resetFilter={resetFilter}
                />
                <div className={classes.orderList_container_header_addOrderBlock}>
                  <select
                    onChange={e => loginAsUser(e.target.value)}
                  >
                    {
                      repsInfo?.length ? repsInfo.map(e => (
                        <option key={e.id} selected={e.sec_user_username === user?.username} value={e.sec_user_username}>{e.sec_user_username}</option>
                      )) : null
                    }
                  </select>
                  <button>
                    <Link to='/order_create'>
                      <span><FontAwesomeIcon icon={faPlus} /></span>
                  Add Order
                    </Link>
                  </button>
                  <button>
                    <Link to='/custom_order_create'>
                      <span><FontAwesomeIcon icon={faPlus} /></span>
                  Add Custom Order
                    </Link>
                  </button>
                </div>
              </div>
              {
                Object.entries(filteredOrders).map(([key, value], index) => (
                  <div key={index + key} className={classes.orderList_container_tableBlock} >
                    <div className={classes.orderList_container_tableBlock_title}>
                      <h3>{key}</h3>
                    </div>
                    <table className={classes.orderList_container_tableBlock_table}>
                      <thead>
                        <tr>
                          {showInfoTdBlock(value) ? <th></th> : null}
                          <th>Job</th>
                          <th>Customer id</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Tur</th>
                          <th>Product</th>
                          <th>Items</th>
                          <th>Quantity</th>
                          {
                            requestPath === 'hot_jobs' || requestPath === 'new_dashboard' ?
                              <>
                                <th>gang</th>
                                <th>press</th>
                              </> : null
                          }
                          <th>Status</th>
                          <th>Comments</th>
                          <th>Repp</th>
                          <th>State</th>
                          <th>First</th>
                          <th>Total</th>
                          <th>Coupon</th>
                          <th>Error</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          value?.length > 0 ? value?.map((order, index) => (
                            <OrderListItem key={index}
                              order={order}
                              requestPath={requestPath}
                              running={settingsInfo.qbBackup[0]?.running}
                              showInfoTdBlock={showInfoTdBlock}
                              value={value}
                            />
                          )) : <p> No Record Data</p>
                        }

                      </tbody>
                    </table>
                  </div>
                ))
              }

            </div>
          </div>
      }
    </>
  )
}

export default OrderList