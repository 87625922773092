import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useClickedOnOutside } from 'src/Hook/useClickedOnOutside'
import { IOrderListByIdBilling } from 'src/api/Preflight/orderList/orderListBilling/model'
import { getBillingData } from 'src/api/Preflight/orderList/orderListBilling/orderListBilling'
import Modal from 'src/ui/modal/Modal'
import { PUBLIC_URL } from 'src/utils/constant'
import classes from '../OrderList.module.scss'
import BillingInformation from '../OrderTabs/OrderBilling/BillingInformation/BillingInformation'
import { IOrderListItemProps } from '../model'
import ExportQr from './ExportQr/ExportQr'

const OrderListItem: FC<IOrderListItemProps> = ({ order, requestPath, running ,showInfoTdBlock, value }): JSX.Element => {
  const navigate = useNavigate()
  const [jobId, setJobId] = useState<string>('')
  const [openModal, setOpenModal] = useState<string>('')
  const [billingInfo, setBillingInfo] = useState<IOrderListByIdBilling>({} as IOrderListByIdBilling)

  const billingData = async (jobId: string): Promise<void> => {
    try {
      setBillingInfo(await getBillingData(jobId))
    } catch (error) {
      console.log(error)

    }
  }
  useEffect(() => {
    if (jobId.length) {
      billingData(jobId)
    }
  },[jobId])

  const qbData = (stageId: number, status: string): {color: string, text: string} => {
    if (stageId !== 900) {
      return {
        color: '',
        text: ''
      }
    }

    if (running > 0) {
      return {
        color: 'purple',
        text: 'Backup in progress'
      }
    }

    switch (status) {
      case 'Ready to Charge':
        return {
          color: 'red',
          text: 'Charge Order'
        }
      case 'Ready to QB Export':
        return {
          color: 'blue',
          text: 'Export to QB'
        }
      case 'Sent To Print':
        return {
          color: 'green',
          text: 'Send To Print'
        }
      default:
        return {
          color: 'gray',
          text: 'Charged Order'
        }
    }
  }

  const closeModal = (): void => {
    setOpenModal('')
  }

  const openConditionalModal = (jobId: string, name: string): void => {
    setOpenModal(name)
    setJobId(jobId)
  }
  const { ref } = useClickedOnOutside(() => setOpenModal(''))

  const getTurnaroundClass = (turnaround: number, stp: number, printAsap: number): string => {
    let className = ''
    if (turnaround === 0 || turnaround === 1) {
      className += `${classes.orderList_container_tableBlock_table_turnaroundRedColor} `
    }
    if (stp === 1 || printAsap === 1) {
      className += `${classes.orderList_container_tableBlock_table_turnaroundYellowBack}`
    }
    return className.trim()
  }

  const getBackgroundColor = (status: string): string => {
    if ((order.hcp !== '0' && order.id_label_label === 602)) {
      return '#98FB98'
    } else if (status === 'Review Ready') {
      return '#C7FAAD'
    } else if (status === 'Remote Review') {
      return '#FFBFBA'
    } else if (status === 'Review Problem') {
      return '#f7ef59' 
    } 
    return ''
    
  }
  
  return (
    <>
      <tr>
        {qbData(order?.job_stage_id, order?.Status).color && <td>
          <button
            style={{ background: qbData(order?.job_stage_id, order?.Status).color || 'none' }}
            className={classes.orderList_container_status}
            name={qbData(order?.job_stage_id, order?.Status).text}
            onClick={e => {
              e.stopPropagation()
              openConditionalModal(String(order.job_id), e.currentTarget.name)
            }}
          >
            {qbData(order?.job_stage_id, order?.Status).text} {' '}
            {order?.Order}
          </button>
        </td>}
      </tr>
      <tr className={String(order.id_label_state) === '603' ? classes.orderList_container_tableBlock_table_record : '' } key={order?.Order}
        onClick={() =>navigate(`/orders/${order.job_id}/summary`)}>
        {showInfoTdBlock(value) && <td>
          {
            order?.color && order.color !== null ?
              <img src={PUBLIC_URL + order?.color?.src} alt=''/> : null
          }
          {order?.hcp !== '0' ?
            <span className={classes.orderList_container_o}>H</span> : null
          }
          {order?.outsourced ?
            <span className={classes.orderList_container_o}>O </span> : null
          }
          {
            order?.directmail ?
              <span className={classes.orderList_container_dM}>DM</span> : null
          }
          {order?.online_design ?
            <span className={classes.orderList_container_o}>OD</span> : null
          }
        </td>}
        <td>{order?.Job}</td>
        <td>{order?.customer_id}</td>
        <td>{order?.Name}</td>
        <td>{order?.Date}</td>
        <td className={getTurnaroundClass(order.turnaround, Number(order.stp), order.print_asap)}>{order?.turnaround}</td>
        <td>{order?.Product}</td>
        <td>{order?.ItemsInOrder}</td>
        <td>{order?.Quantity}</td>
        {
          requestPath === 'hot_jobs' || requestPath === 'new_dashboard' ?
            <>
              <td>{order?.gang_id}</td>
              <td>{order?.gang_press}</td>
            </> : null
        }
        <td style={{ background:getBackgroundColor(order.Status) }} 
        >{order?.Status}</td>
        <td>{order.comments || ''}</td>
        <td>{order?.username || ''}</td>
        <td>{order?.shipping_state === '&nbsp;' ? '' : order?.shipping_state}</td>
        <td>{Number(order?.first_order) > 0 ? '' : 'Yes'}</td>
        <td>{order?.Total}</td>
        <td>{order?.sales_coupon_code}</td>
        <td>{order?.errorMessage}</td>
      </tr>
      {
        openModal === 'Charge Order' ? <Modal modalRef={ref} isShowing>
          <BillingInformation
            billingInfo={billingInfo}
            setBillingInfo={setBillingInfo}
            isSave
            isCharge
            jobId={jobId}
            closeModal={closeModal}
          />
        </Modal> : null
      }
      {
        openModal === 'Export to QB' ?
          <ExportQr
            jobId={jobId}
            closeModal={closeModal}

          /> : null
      }
    </>
  )
}

export default OrderListItem