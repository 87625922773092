import { useEffect, useState } from 'react'
import Options from './Options/Options'
import classes from './PrintShippmentLabel.module.scss'
import ShipFrom from './ShipFrom/ShipFrom'
import ShipTo from './ShipTo/ShipTo'
import { IGetPrintShippmentLabelInfo, IPrintShippmentLabelPostData } from 'src/api/postApproval/boxing/model'
import { getPrintShippmentLabel, postPrintShippmentLabel } from 'src/api/postApproval/boxing/boxing'
import { useParams } from 'react-router-dom'
import Loading from 'src/Components/Loading/Loading'
import ShippmentPackages from './ShippmentPackages/ShippmentPackages'
import { IErrorMessage, IResponseError } from 'src/utils/errorMessage/model'
import { toastErrorOrFieldError } from 'src/utils/errorMessage/errorMessage'
import Manage from './Manage/Manage'
const PrintShippmentLabel = (): JSX.Element => {
  const { id } = useParams()  
  const [printShippmentLabelInfo, setPrintShippmentLabelInfo] = useState<IGetPrintShippmentLabelInfo>({} as IGetPrintShippmentLabelInfo)
  const [sendDataForBack, setSendDataForBack] = useState<IPrintShippmentLabelPostData>({} as IPrintShippmentLabelPostData)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorRequest, setErrorRequest] = useState<IErrorMessage>({})
  const [isManage, setIsManage] = useState<boolean>(true)
  const printShippmentLabelData = async (id: string): Promise<void> => {
    try {
      const res = await getPrintShippmentLabel(id, '1')
      setPrintShippmentLabelInfo(res)
      setSendDataForBack({
        Shipper_ShipperNumber: res.shipFrom.UPSAccount,
        Shipper_Name: res.shipFrom.Company,
        Shipper_AttentionName: res.shipFrom.Name,
        Shipper_PhoneNumber: res.shipFrom.Phone,
        Shipper_AddressLine1: res.shipFrom.Shipper_AddressLine1,
        Shipper_AddressLine2: res.shipFrom.Shipper_AddressLine2,
        Shipper_City: res.shipFrom.City,
        Shipper_StateProvinceCode:  res.shipFrom?.shipperStates?.[0]?.options?.[0].value || 'NY',
        ShipTo_CountryCode:res.shipTo.Countries[0].value,
        Shipper_PostalCode: res.shipFrom.ZIP,
        ShipTo_CompanyName: res.shipTo.Company,
        ShipTo_AttentionName: res.shipTo.Name.value + ' ' + res.shipTo?.Name?.label,
        ShipTo_PhoneNumber: res.shipTo.Phone,
        ShipTo_AddressLine1: res.shipTo.ShipToAddressLine1,
        ShipTo_AddressLine2: res.shipTo.ShipToAddressLine2 || '',
        ShipTo_City: res.shipTo.City,
        ShipTo_StateProvinceCode: res.shipTo?.shipperStates[0]?.options?.[0].value || 'TX',
        ShipTo_PostalCode: res.shipTo.ZIP,
        ShipTo_AccountNumber: '',
        ShipTo_AccountPostalCode: '',
        Notification: 0,
        Notification_EMailAddress: res.shipTo.email,
        Service: res.options.serviceList[0].value || 'US-PM',
        PaymentInformation: res.options.paymentOptions[0].value,
        ReferenceNumberValue: String(res.options.Reference),
        InvoiceTotal: res.options.InvoiceTotal,
        PackageDescription: res.options.packageDescription,
        Package_PackagingType_Code: ['02'],
        Package_PackageWeight_Weight:[Number(res.packages.boxes[0]?.box_weight_in_pounds)],
        InsuredValue_MonetaryValue: [100],
        Package_Dimensions_Length: [0],
        Package_Dimensions_Width: [0],
        Package_Dimensions_Height: [0],
        approve:'Request Shipment',
        OORDERID: res.orderId,
        
      })
      // setIsManage(res.isShowMANAGELabels)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (id) {
      printShippmentLabelData(id)
    }
  },[id])

  const createPrintShippmentLabel = async (): Promise<void> => {
    try {
      await postPrintShippmentLabel(Number(id),'1', sendDataForBack)
      setIsManage(true)
    } catch (error) {
      const errorMessage = error as IResponseError
      toastErrorOrFieldError(errorMessage?.response?.data, setErrorRequest)
    }
  }

  const handleChangeData = (field: string, value: string | number, packageIndex?: number): void => {
    if (!Object.values(errorRequest).every(error => error === '')) {
      setErrorRequest({ ...errorRequest, [field]: '' })
    }
    if (typeof packageIndex === 'number') {
      setSendDataForBack(prev => {
        const updatedPrev = { ...prev }
        if (!Array.isArray(updatedPrev[field])) {
          updatedPrev[field] = []
        }
        const fieldArray = [...updatedPrev[field]]
        if (fieldArray[packageIndex] !== undefined) {
          fieldArray[packageIndex] = value
        } else {
          fieldArray.push(value)
        }
        updatedPrev[field] = fieldArray
  
        return updatedPrev
      })
    } else {
      setSendDataForBack(prev => ({
        ...prev,
        [field]: value
      }))
    }
  }
  
  return (
    <>
      {
        isLoading ? <Loading/> :
          <div className={classes.printShippmentLabel}>
            <div className={classes.printShippmentLabel_container}>
              {
                isManage ? 
                  <Manage shippingType={printShippmentLabelInfo.shippingType} setIsManage={setIsManage}/> :
                  <>
                    <ShipFrom data={printShippmentLabelInfo.shipFrom} sendDataForBack={sendDataForBack} handleChangeData={handleChangeData}/>
                    <ShipTo
                      data={printShippmentLabelInfo.shipTo}
                      sendDataForBack={sendDataForBack}
                      handleChangeData={handleChangeData}
                      errorRequest={errorRequest}
                    />
                    <Options data={printShippmentLabelInfo.options} sendDataForBack={sendDataForBack} handleChangeData={handleChangeData}/>
                    <ShippmentPackages
                      data={printShippmentLabelInfo.packages}
                      handleChangeData={handleChangeData}
                      setSendDataForBack={setSendDataForBack}
                      createPrintShippmentLabel={createPrintShippmentLabel}
                      errorRequest={errorRequest}
                    />
                  </>
              }
            </div>
          </div>
      }
    </>
  )

}

export default PrintShippmentLabel