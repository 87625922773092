import { FC } from 'react'
import classes from './Tab.module.scss'
import { ITabProps } from './model'
const Tab: FC<ITabProps> = ({ tab, changeTab, tabName }): JSX.Element => {

  return (
    <button className={tabName === tab ? classes.active : '' }
      onClick={() => changeTab?.(tab)}>
      {tab }
    </button>
  )
}

export default Tab