import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MetaData } from '../../ui/MetaData'
import classes from './Customers.module.scss'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import PageHeader from '../Layout/PageHeader/PageHeader'
import { useEffect, useState } from 'react'
import { IGetCustomers } from 'src/api/customers/model'
import Loading from '../Loading/Loading'
import Filter from 'src/ui/Filter/Filter'
import { getAllCustomers } from 'src/api/customers/customers'
import { formatUSDateTime } from 'src/utils/dateConvertToString'
import { IParams } from 'src/api/Preflight/orderList/OrderListNote/model'
import useNextStepScroll from 'src/Hook/useNextStepScrol'

const Customers = (): JSX.Element => {
  const navigate = useNavigate()
  const { isScroll, turnOfScroll } = useNextStepScroll()
  const [customersInfo, setCustomersInfo] = useState<IGetCustomers>({} as IGetCustomers)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [params, setParams] = useState<IParams>({
    page: '1',
    limit: '50'
  })

  const allCustomers = async (): Promise<void> => {
    return getAllCustomers(params)
      .then(res => {
        if (res.pagination.page !== 1) {
          setCustomersInfo(prev => {
            return {
              data: [...prev.data, ...res.data],
              pagination: res.pagination
            }
          })
        } else {
          setCustomersInfo(res)
        }
        turnOfScroll()
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => {
        setIsLoading(false)
      })

  }

  useEffect(() => {
    allCustomers()
  }, [params])
  
  useEffect(() => {
    if (isScroll && Number(params.page) < customersInfo?.pagination?.totalPages) {
      setParams({
        ...params,
        page: String(Number(params.page) + 1)
      })
    }
  }, [isScroll])

  const customerFilter = (data: IParams): void => {
    const newData: IParams = {}
    Object.keys(data).forEach(key => {
      if (data[key].length > 0) {
        newData[key] = data[key]
      }
    })
    newData.page = '1'
    setParams(newData)

  }
  const resetFilter = (): void => {
    setParams({
      page: '1',
      limit: '50'
    })
    setIsLoading(true)
  }



  return (
    <>
      <MetaData title='Customers' /> 
      {
        isLoading ? <Loading/> :
          <div className={classes.customer}> 
            <PageHeader pageName='Customers' />
            <div className={classes.customer_container}>
              <div className={classes.customer_container_header}>
                <Filter
                  params={params}
                  pageName='customer'
                  handleFilter={customerFilter}
                  resetFilter={resetFilter}
                />
                <button onClick={() => navigate('create')}>New Customer</button>
              </div>
              <table className={classes.customer_container_table}>
                <thead>
                  <tr>
                    <th>User Id</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Verified at</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    customersInfo.data.length ? customersInfo.data.map((customer, index) => (
                      <tr key={index}>
                        <td>{customer.userId}</td>
                        <td>{customer.firstName + ' ' + customer.lastName }</td>
                        <td>{customer.email}</td>
                        <td>{formatUSDateTime(customer.emailVerifiedAt)}</td>
                        <td>{formatUSDateTime(customer.created)}</td>
                        <td>
                          <Link to={`${customer.userId}/edit`}>
                            <FontAwesomeIcon icon={faPen} color='#4297d7'/>
                          </Link>
                        </td>
                      </tr>
                    )) : <p>No Record Data</p>
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
    </>
  )
}

export default Customers